import axios from "axios";
import React, { useState } from "react";
import { FaCheck, FaUpload } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useMessageData, useUserStore } from "../context/UserContext";
import { CheckAuthetication } from "../pages/Login";

const AddPartner = () => {
  const navigate = useNavigate();
  const { logout } = useUserStore();
  const { setErr, setMessage, setShow, setIcon } = useMessageData();
  const [newPartner, setNewPartner] = useState({
    name: "",
    website: "",
    logo: null,
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPartner((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      handleFileUpload(file);
    }
  };

  const handleFileUpload = (file) => {
    setNewPartner((prev) => ({
      ...prev,
      logo: file,
    }));
    const previewUrl = URL.createObjectURL(file);
    setLogoPreview(previewUrl);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      handleFileUpload(file);
    }
  };

  const clearLogoPreview = () => {
    setLogoPreview(null);
    setNewPartner((prev) => ({
      ...prev,
      logo: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPartner.name || !newPartner.website || !newPartner.logo) {
      alert("Please fill in all fields");
      return;
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(newPartner)) {
      formData.append(key, value);
    }
    try {
      setIsLoading(true);
      const check = await CheckAuthetication();
      if (!check) {
        logout();
        navigate("/login");
        return;
      }
      await axios.post(
        process.env.REACT_APP_API_URL + "admin/partner",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      setNewPartner({ name: "", website: "", logo: null });
      setLogoPreview(null);
      setErr(false);
      setMessage("Partner Added Successfully");
      setIcon(<FaCheck />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    } catch (error) {
      console.log(error);
      setErr(true);
      setMessage("Fail To Add Partner");
      setIcon(<FaXmark />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-3 space-y-8">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black/10 z-[999999]">
          <div className="loader"></div>
        </div>
      )}
      <div className="bg-white rounded-xl shadow-lg p-8 transition-all duration-300">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">
          Add New Partner
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Company Name
              </label>
              <input
                type="text"
                name="name"
                value={newPartner.name}
                onChange={handleInputChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-200  transition-all duration-200 focus:outline-primary"
                placeholder="Enter company name"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Website Link
              </label>
              <input
                type="url"
                name="website"
                value={newPartner.website}
                onChange={handleInputChange}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:outline-primary transition-all duration-200"
                placeholder="https://example.com"
              />
            </div>
          </div>

          <div
            className={`border-2 border-dashed rounded-xl p-8 text-center  transition-all duration-200 ${
              isDragging
                ? "border-primary bg-red-50"
                : "border-gray-300 hover:border-primary"
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {!logoPreview ? (
              <div className="space-y-4">
                <FaUpload className="w-12 h-12 mx-auto text-gray-400" />
                <div>
                  <p className="text-gray-600">
                    Drag and drop your logo here, or
                  </p>
                  <label className="inline-block mt-2 px-4 py-2 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 transition-colors duration-200">
                    <span className="text-primary font-medium">
                      Browse files
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleLogoChange}
                    />
                  </label>
                </div>
              </div>
            ) : (
              <div className="relative">
                <img
                  src={logoPreview}
                  alt="Logo preview"
                  className="max-h-48 mx-auto rounded-lg"
                />
                <button
                  type="button"
                  onClick={clearLogoPreview}
                  className="absolute top-2 right-2 p-1 bg-red-100 rounded-full hover:bg-red-200 transition-colors duration-200"
                >
                  <FaXmark className="w-4 h-4 text-red-600" />
                </button>
              </div>
            )}
          </div>

          <button
            type="submit"
            className="w-full md:w-auto px-6 py-3 bg-primary text-white rounded-lg hover:bg-primaryHover focus:outline-none  transition-all duration-200"
          >
            Add Partner
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddPartner;

import axios from "axios";
import React, { useState } from "react";
import { FaCheck, FaMailBulk } from "react-icons/fa";
import { useMessageData, useUserStore } from "../context/UserContext";

const ForgotPassword = () => {
  const { user, logout } = useUserStore();
  const { setErr, setMessage, setShow, setIcon } = useMessageData();
  const [email, setEmail] = useState(user ? user.email : "");
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setStatus({
        type: "error",
        message: "Please enter your email address",
      });
      return;
    }

    if (!validateEmail(email)) {
      setStatus({
        type: "error",
        message: "Please enter a valid email address",
      });
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await axios.put(
        process.env.REACT_APP_API_URL + "pass_recovery",
        {
          email,
        },
        {
          withCredentials: true,
        }
      );
      setMessage("Check Your Email To Reset Password");
      setErr(false);
      setIcon(<FaCheck />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
      setEmail("");
    } catch (error) {
      setMessage("Something went wrong. Please try again");
      setErr(false);
      setIcon(<FaCheck />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-[600px]">
        <h2 className="text-center text-5xl font-extrabold text-gray-900">
          Forgot your password?
        </h2>
        <p className="mt-2 text-center text-[1.15rem] text-gray-600">
          Don't worry! It happens. Please enter the email address associated
          with your account. and we will send you a confirmation code to reset
          your password
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {status.message && (
              <div
                className={`p-3 rounded-lg text-sm ${
                  status.type === "error"
                    ? "bg-red-50 text-red-700"
                    : "bg-green-50 text-green-700"
                }`}
              >
                {status.message}
              </div>
            )}

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaMailBulk className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-200 rounded-lg  text-sm focus:outline-gray-300"
                  placeholder="Enter your email"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-primaryHover hover:bg-primaryHover focus:outline-primary  ${
                  isLoading ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <div className="flex items-center">Sending...</div>
                ) : (
                  "Reset Password"
                )}
              </button>
            </div>

            {!user && (
              <div className="text-sm text-center border border-primary rounded-md py-2">
                <a
                  href="/login"
                  className="font-medium text-primaryHover hover:text-primary"
                >
                  Back to login
                </a>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaCheck,
  FaCheckCircle,
  FaEye,
  FaEyeSlash,
  FaLock,
} from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useMessageData, useUserStore } from "../context/UserContext";

const PasswordInput = ({
  name,
  placeholder,
  value,
  showPassword,
  onToggle,
  onFocus,
  onBlur,
  onChange,
}) => (
  <div className="relative">
    <div
      className={`flex items-center rounded-lg border ${
        onFocus ? "border-primary" : "border-gray-300"
      } bg-white px-3 py-2 shadow-sm`}
    >
      <FaLock className="h-5 w-5 text-gray-400" />
      <input
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className="ml-2 flex-1 border-0 bg-transparent p-0 text-gray-900 placeholder:text-gray-400 focus:outline-none"
      />
      <button
        type="button"
        onClick={onToggle}
        className="text-gray-400 hover:text-gray-600"
      >
        {showPassword ? (
          <FaEyeSlash className="h-5 w-5" />
        ) : (
          <FaEye className="h-5 w-5" />
        )}
      </button>
    </div>
  </div>
);

const PasswordReset = () => {
  const { token } = useParams();
  const { setErr, setMessage, setShow, setIcon } = useMessageData();
  const navigate = useNavigate();
  const { user } = useUserStore();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirm: false,
  });
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState("");

  const requirements = [
    { text: "At least 8 characters", test: (pass) => pass.length >= 8 },
    {
      text: "At least one uppercase letter",
      test: (pass) => /[A-Z]/.test(pass),
    },
    {
      text: "At least one lowercase letter",
      test: (pass) => /[a-z]/.test(pass),
    },
    { text: "At least one number", test: (pass) => /\d/.test(pass) },
    {
      text: "At least one special character",
      test: (pass) => /[!@#$%^&*(),.?":{}|<>]/.test(pass),
    },
  ];

  const validatePassword = () => {
    if (formData.password !== formData.confirmPassword) {
      setStatus({
        type: "error",
        message: "Passwords do not match",
      });
      return false;
    }

    const failedRequirements = requirements.filter(
      (req) => !req.test(formData.password)
    );
    if (failedRequirements.length > 0) {
      setStatus({
        type: "error",
        message: "Password does not meet all requirements",
      });
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setStatus({ type: "", message: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword()) {
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_API_URL + "pass_reset",
        {
          newPassword: formData.password,
          token,
        },
        {
          withCredentials: true,
        }
      );
      setMessage("Password Reset Success");
      setErr(false);
      setIcon(<FaCheck />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
      if (user) {
        user.role = "admin" ? navigate("/dashboard") : navigate("/");
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      setMessage("Something went wrong. Please try again");
      setErr(false);
      setIcon(<FaXmark />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  useEffect(() => {
    console.log(token);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-3xl font-extrabold text-gray-900">
          Reset Your Password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Please choose a strong password that meets all the requirements below.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {status.message && (
              <div
                className={`p-3 rounded-lg text-sm ${
                  status.type === "error"
                    ? "bg-red-50 text-red-700"
                    : "bg-green-50 text-green-700"
                }`}
              >
                {status.message}
              </div>
            )}

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                New Password
              </label>
              <PasswordInput
                name="password"
                placeholder="Enter new password"
                value={formData.password}
                showPassword={showPasswords.password}
                onToggle={() => togglePasswordVisibility("password")}
                onFocus={() => setFocusedInput("password")}
                onBlur={() => setFocusedInput("")}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Confirm New Password
              </label>
              <PasswordInput
                name="confirmPassword"
                placeholder="Confirm new password"
                value={formData.confirmPassword}
                showPassword={showPasswords.confirm}
                onToggle={() => togglePasswordVisibility("confirm")}
                onFocus={() => setFocusedInput("confirmPassword")}
                onBlur={() => setFocusedInput("")}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Password Requirements:
              </label>
              <div className="space-y-2 text-sm">
                {requirements.map((req, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    {req.test(formData.password) ? (
                      <FaCheckCircle className="h-4 w-4 text-green-500" />
                    ) : (
                      <FaXmark className="h-4 w-4 text-gray-300" />
                    )}
                    <span
                      className={
                        req.test(formData.password)
                          ? "text-green-600"
                          : "text-gray-500"
                      }
                    >
                      {req.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-primary hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${
                isLoading ? "opacity-75 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <div className="flex items-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2" />
                  Resetting Password...
                </div>
              ) : (
                "Reset Password"
              )}
            </button>

            <div className="text-sm text-center">
              <a
                href="/login"
                className="font-medium text-primary hover:text-primary"
              >
                Back to login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;

import { data } from "@remix-run/router";
import axios from "axios";
import React, { useState, useRef } from "react";
import { FaCamera, FaCheck, FaPen, FaTrash, FaUser } from "react-icons/fa";
import { RiLoader4Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useUserStore } from "../context/UserContext";
import { CheckAuthetication } from "./Login";
import { MdOutlineSecurity } from "react-icons/md";
import { FaXmark } from "react-icons/fa6";
import { useMessageData } from "../context/UserContext";

export default function Accountsetting() {
  const { setErr, setMessage, setShow, setIcon } = useMessageData();
  const navigate = useNavigate();
  const NameRef = useRef(null);
  const EmailRef = useRef(null);
  const DeleteingRef = useRef(null);
  const PasswordRef = useRef(null);
  const PrimaryRef = useRef(null);
  const ActivationRef = useRef(null);
  const [uploading, setuploading] = useState(false);
  const { user, login, logout } = useUserStore();
  const inChangeImg = useRef();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [lastName, setLastName] = useState(user?.lastName);
  let FullName = user ? `${user?.firstName} ${user?.lastName}` : "Unknown";

  const [code, setCode] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);

  const handleChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 3) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const UpdateName = async (e) => {
    e.preventDefault();
    const check = await CheckAuthetication();
    if (!check) {
      logout();
      navigate("/login");
      return;
    }
    e.preventDefault();
    if (
      firstName === user?.firstName &&
      lastName === user?.lastName &&
      (firstName.length < 4 || lastName.length < 4)
    )
      return;
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_API_URL + "update",
        {
          firstName,
          lastName,
        },
        {
          withCredentials: true,
        }
      );
      login(data.user);
      NameRef?.current?.classList.add("scale-0");
    } catch (error) {
      console.log(data);
    }
  };

  const AddEmail = async (e) => {
    e.preventDefault();
    const check = await CheckAuthetication();
    if (!check) {
      logout();
      navigate("/login");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (secondaryEmail === user?.email || !emailRegex.test(secondaryEmail))
      return;
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_API_URL + "add_email",
        {
          secondaryEmail,
          password,
        },
        {
          withCredentials: true,
        }
      );
      EmailRef?.current?.classList.add("scale-0");
      ActivationRef?.current?.classList.remove("scale-0");
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const ActivateEmail = async (e) => {
    e.preventDefault();
    const check = await CheckAuthetication();
    if (!check) {
      logout();
      navigate("/login");
      return;
    }
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_API_URL + "activate_email",
        {
          activationCode: parseInt(code.join("")),
        },
        {
          withCredentials: true,
        }
      );
      login(data.user);
      ActivationRef?.current?.classList.add("scale-0");
    } catch (error) {
      console.log(error);
    }
  };

  const DelteSecondEmail = async (e) => {
    e.preventDefault();
    setuploading(true);
    const check = await CheckAuthetication();
    if (!check) {
      logout();
      navigate("/login");
      return;
    }
    try {
      const { data } = await axios.delete(
        process.env.REACT_APP_API_URL + "delete_second_email",
        {
          withCredentials: true,
        }
      );
      login(data.user);
      DeleteingRef?.current?.classList.add("scale-0");
    } catch (error) {
      console.log(error);
    } finally {
      setuploading(false);
    }
  };

  const MarkEmailPrimary = async (e) => {
    e.preventDefault();
    setuploading(true);
    const check = await CheckAuthetication();
    if (!check) {
      logout();
      navigate("/login");
      return;
    }
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_API_URL + "primary_email",
        {},
        {
          withCredentials: true,
        }
      );
      login(data.user);
      PrimaryRef?.current?.classList.add("scale-0");
    } catch (error) {
      console.log(error);
    } finally {
      setuploading(false);
    }
  };

  const handleProfilePictureChange = async (e) => {
    try {
      const check = await CheckAuthetication();
      if (!check) {
        logout();
        navigate("/login");
        return;
      }
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await axios.put(
        process.env.REACT_APP_API_URL + "avatar",
        formData,
        {
          withCredentials: true,
        }
      );
      login(data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const ChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setMessage("New Password Not Match Confirm New Password");
      setErr(true);
      setIcon(<FaXmark />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setMessage("Make Strong Password");
      setErr(true);
      setIcon(<FaXmark />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    }
    try {
      const check = await CheckAuthetication();
      if (!check) {
        logout();
        navigate("/login");
        return;
      }
      await axios.put(
        process.env.REACT_APP_API_URL + "password",
        {
          password,
          newPassword,
        },
        {
          withCredentials: true,
        }
      );
      setMessage("Password changed successfully");
      setErr(false);
      setIcon(<FaCheck />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    } catch (error) {
      console.log(error);
      setMessage("Faild To Chnage The Password");
      setErr(true);
      setIcon(<FaXmark />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    }
  };

  return (
    <div className="w-full h-full p-4 flex justify-center items-center text-blackColor bg-whiteColor">
      <form className="flex items-start">
        {/* Deleting Email */}
        <div
          ref={DeleteingRef}
          className=" fixed w-[100vw] scale-0 top-0 left-0 h-[100dvh] bg-[#0000004c] z-30 flex justify-center items-center duration-300"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[999999] p-4">
            <div className="bg-white rounded-2xl shadow-xl p-8 max-w-sm w-full transform transition-all">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
                  <FaTrash className="h-6 w-6 text-red-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Delete Secondary Email
                </h3>
                <p className="text-sm text-gray-500 mb-6">
                  Are you sure you want to delete this email? This action cannot
                  be undone.
                </p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      DeleteingRef.current.classList.add("scale-0");
                    }}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => DelteSecondEmail(e)}
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200"
                  >
                    {uploading ? (
                      <>
                        <RiLoader4Line className="w-5 h-5 animate-spin mr-2" />
                      </>
                    ) : (
                      <>Delete</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Primary Email */}
        <div
          ref={PrimaryRef}
          className=" fixed w-[100vw] scale-0 top-0 left-0 h-[100dvh] bg-[#0000004c] z-30 flex justify-center items-center duration-300"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[999999] p-4">
            <div className="bg-white rounded-2xl shadow-xl p-8 max-w-sm w-full transform transition-all">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
                  <MdOutlineSecurity className="h-6 w-6 text-red-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Mark Email As Primry
                </h3>
                <p className="text-sm text-gray-500 mb-6">
                  Are you sure you want to mark this email as Primry?
                </p>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      PrimaryRef.current.classList.add("scale-0");
                    }}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => MarkEmailPrimary(e)}
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200"
                  >
                    {uploading ? (
                      <>
                        <RiLoader4Line className="w-5 h-5 animate-spin mr-2" />
                      </>
                    ) : (
                      <>Mark</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {Edit Name} */}
        <div
          ref={NameRef}
          className=" fixed w-[100vw] scale-0 top-0 left-0 h-[100dvh] bg-[#0000004c] z-30 flex justify-center items-center duration-300"
        >
          <div className=" w-[400px] bg-white p-5 rounded-md shadow-md z-10">
            <h2 className="text-center mb-5 text-[1.4rem] font-bold">
              {" "}
              Edit Name{" "}
            </h2>
            <label className="mb-3 font-semibold text-gray-500">
              {" "}
              First Name :{" "}
            </label>
            <div className="flex items-center rounded-md w-full bg-white border border-black p-1  mb-5">
              <FaUser className="text-black text-base mr-2" />
              <input
                value={firstName}
                type="text"
                placeholder={user ? user.firstName : "First Name"}
                id="firstName"
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                className="bg-transparent rounded-md text-black text-base flex-1 border-none outline-none"
                minLength={4}
              />
            </div>

            <label className="mb-3 font-semibold text-gray-500">
              {" "}
              Last Name :{" "}
            </label>
            <div className="flex items-center rounded-md w-full bg-white border border-black p-1 mb-5">
              <FaUser className="text-black text-base mr-2" />
              <input
                value={lastName}
                type="text"
                placeholder={user ? user.lastName : "Last Name"}
                id="firstName"
                name="firstName"
                onChange={(e) => setLastName(e.target.value)}
                className="bg-transparent rounded-md text-black text-base flex-1 border-none outline-none"
                minLength={4}
              />
            </div>
            <div className="flex justify-center gap-3 w-full">
              <button
                type="submit"
                onClick={(e) => UpdateName(e)}
                className=" py-2 w-1/2 font-semibold rounded-md text-whiteColor bg-primary hover:bg-primaryHover duration-150 "
              >
                Update Name
              </button>
              <button
                type="button"
                className="w-1/2 py-2 font-semibold rounded-md bg-blue-500 hover:bg-blue-600 duration-150 text-whiteColor "
                onClick={(e) => {
                  e.preventDefault();
                  NameRef.current.classList.add("scale-0");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        {/* {Edit Email} */}
        <div
          ref={EmailRef}
          className=" fixed w-[100vw] scale-0 top-0 left-0 h-[100dvh] bg-[#0000004c] z-30 flex justify-center items-center duration-300"
        >
          <div className=" w-[400px] bg-white p-5 rounded-md shadow-md z-10">
            <h2 className="text-center mb-5 text-[1.4rem] font-bold">
              {" "}
              Secondary Email{" "}
            </h2>
            <label className="mb-3 font-semibold text-gray-500">
              {" "}
              Email :{" "}
            </label>
            <div className="flex items-center rounded-md w-full bg-white border border-black p-1  mb-5">
              <FaUser className="text-black text-base mr-2" />
              <input
                value={secondaryEmail}
                type="text"
                placeholder={"exemple@gmail.com"}
                id="secondaryEmail"
                name="secondaryEmail"
                onChange={(e) => setSecondaryEmail(e.target.value)}
                className="bg-transparent rounded-md text-black text-base flex-1 border-none outline-none"
                minLength={4}
              />
            </div>

            <label className="mb-3 font-semibold text-gray-500">
              {" "}
              Password :{" "}
            </label>
            <div className="flex items-center rounded-md w-full bg-white border border-black p-1 mb-5">
              <FaUser className="text-black text-base mr-2" />
              <input
                value={password}
                type="password"
                placeholder={"*********"}
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                className="bg-transparent rounded-md text-black text-base flex-1 border-none outline-none"
                minLength={4}
              />
            </div>
            <div className="flex justify-center gap-3 w-full">
              <button
                type="submit"
                onClick={(e) => AddEmail(e)}
                className=" py-2 w-1/2 font-semibold rounded-md text-whiteColor bg-primary hover:bg-primaryHover duration-150 "
              >
                Add Email
              </button>
              <button
                type="button"
                className="w-1/2 py-2 font-semibold rounded-md bg-blue-500 hover:bg-blue-600 duration-150 text-whiteColor "
                onClick={(e) => {
                  e.preventDefault();
                  EmailRef.current.classList.add("scale-0");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        {/* Change Password */}
        <div
          ref={PasswordRef}
          className=" fixed w-[100vw] scale-0 top-0 left-0 h-[100dvh] bg-[#0000004c] z-30 flex justify-center items-center duration-300"
        >
          <div className=" w-[400px] bg-white p-5 rounded-md shadow-md z-10">
            <h2 className="text-center mb-5 text-[1.4rem] font-bold">
              {" "}
              Change Password{" "}
            </h2>
            <label className="mb-3 font-semibold text-gray-500">
              {" "}
              password :{" "}
            </label>
            <div className="flex items-center rounded-md w-full bg-white border border-black p-1 mb-1">
              <FaUser className="text-black text-base mr-2" />
              <input
                value={password}
                type="password"
                placeholder={"*******"}
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                className="bg-transparent rounded-md text-black text-base flex-1 border-none outline-none"
                minLength={6}
              />
            </div>
            <Link
              to={"/forget-password"}
              className="block text-primary text-[0.8rem] font-bold cursor-pointer mb-5"
            >
              {" "}
              Forgot Password{" "}
            </Link>
            <label className="mb-3 font-semibold text-gray-500">
              {" "}
              New Password :{" "}
            </label>
            <div className="flex items-center rounded-md w-full bg-white border border-black p-1 mb-5">
              <FaUser className="text-black text-base mr-2" />
              <input
                value={newPassword}
                type="password"
                placeholder={"*********"}
                id="newPassword"
                name="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
                className="bg-transparent rounded-md text-black text-base flex-1 border-none outline-none"
                minLength={6}
              />
            </div>
            <label className="mb-3 font-semibold text-gray-500">
              {" "}
              Confirm New Password :{" "}
            </label>
            <div className="flex items-center rounded-md w-full bg-white border border-black p-1 mb-5">
              <FaUser className="text-black text-base mr-2" />
              <input
                value={confirmNewPassword}
                type="password"
                placeholder={"*********"}
                name="confirmNewPassword"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="bg-transparent rounded-md text-black text-base flex-1 border-none outline-none"
                minLength={6}
              />
            </div>
            <div className="flex justify-center gap-3 w-full">
              <button
                type="submit"
                onClick={(e) => ChangePassword(e)}
                className=" py-2 w-1/2 font-semibold rounded-md text-whiteColor bg-primary hover:bg-primaryHover duration-150 "
              >
                Change
              </button>
              <button
                type="button"
                className="w-1/2 py-2 font-semibold rounded-md bg-blue-500 hover:bg-blue-600 duration-150 text-whiteColor "
                onClick={(e) => {
                  e.preventDefault();
                  PasswordRef.current.classList.add("scale-0");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        {/* {Activate Email} */}
        <div
          ref={ActivationRef}
          className=" fixed w-[100vw] scale-0 top-0 left-0 h-[100dvh] bg-[#0000004c] z-50 flex justify-center items-center duration-300"
        >
          <div className="p-10 px-20 rounded-md bg-whiteColor">
            {" "}
            <h1 className="text-center mb-7 text-[1.8rem] font-semibold">
              {" "}
              Email Activation{" "}
            </h1>
            <div className="flex justify-center items-center space-x-3 mb-5">
              {code.map((digit, index) => (
                <input
                  key={index}
                  type="number"
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  ref={(el) => (inputsRef.current[index] = el)}
                  className="w-12 h-12 text-center text-2xl font-bold border-2 border-gray-300 rounded-lg focus:border-primary focus:outline-none focus:ring-2 focus:ring-primary transition-all"
                  maxLength={1}
                  inputMode="numeric"
                />
              ))}
            </div>
            <div className="flex justify-center gap-3 w-full">
              <button
                type="submit"
                onClick={(e) => ActivateEmail(e)}
                className=" py-2 w-1/2 font-semibold rounded-md text-whiteColor bg-primary hover:bg-primaryHover duration-150 "
              >
                Add Email
              </button>
              <button
                type="button"
                className="w-1/2 py-2 font-semibold rounded-md bg-blue-500 hover:bg-blue-600 duration-150 text-whiteColor "
                onClick={(e) => {
                  e.preventDefault();
                  ActivationRef.current.classList.add("scale-0");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        {/* {Image} */}
        <div className="relative mr-4">
          <div className="w-[500px] bg-[#00000013] h-[500px] p-5 rounded-md overflow-hidden">
            <img
              src={user?.image}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="absolute bottom-3 right-3 z-10">
            <input
              ref={inChangeImg}
              type="file"
              id="profilePictureLabel"
              name="profilePicture"
              accept="image/*"
              onChange={(e) => handleProfilePictureChange(e)}
              className="hidden"
            />
            <div
              className="w-fit flex items-center text-whiteColor bg-primary duration-150 font-semibold justify-center rounded-md px-3 py-2 gap-2  cursor-pointer"
              onClick={() => inChangeImg.current.click()}
            >
              Change Picture <FaCamera className="" />
            </div>
          </div>
        </div>

        {/* {Profile} */}
        <div>
          <p className="text-gray-700 text-[1.1rem] font-normal"> Name: </p>
          <p className="text-[1.2rem] font-semibold"> {FullName} </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              NameRef?.current?.classList.remove("scale-0");
            }}
            className="text-primary flex justify-start items-center gap-2 mb-6 cursor-pointer px-2 py-1 rounded-md w-fit underline underline-offset-2"
          >
            {" "}
            <FaPen /> Edit Name
          </button>

          <p className="text-gray-700 text-[1.1rem] font-normal"> Email: </p>
          <p className="text-[1.2rem] font-semibold"> {user?.email} </p>
          {!user?.secondary_email && (
            <button
              onClick={(e) => {
                e.preventDefault();
                EmailRef?.current?.classList.remove("scale-0");
              }}
              className="text-primary mb-6 flex justify-start items-center gap-2 cursor-pointer px-2 py-1 rounded-md w-fit underline underline-offset-2"
            >
              {" "}
              <FaPen /> Add Email
            </button>
          )}

          {user?.secondary_email && (
            <div>
              <p className="text-gray-700 text-[1.1rem] font-normal mt-6">
                {" "}
                Secondary Email:{""}
              </p>
              <p className="text-[1.2rem] font-semibold mb-2">
                {" "}
                {user?.secondary_email}{" "}
              </p>
              <div className="flex justify-center gap-3 w-full">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    DeleteingRef.current.classList.remove("scale-0");
                  }}
                  className=" py-2 w-1/2 font-semibold rounded-md text-whiteColor bg-primary hover:bg-primaryHover duration-150 "
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="w-1/2 py-2 font-semibold rounded-md bg-blue-500 hover:bg-blue-600 duration-150 text-whiteColor "
                  onClick={(e) => {
                    e.preventDefault();
                    PrimaryRef.current.classList.remove("scale-0");
                  }}
                >
                  Mark as Primary
                </button>
              </div>
            </div>
          )}

          <p className="text-gray-700 text-[1.1rem] font-normal mt-6">
            {" "}
            Password:{" "}
          </p>
          <p className="text-[1.2rem] font-bold"> ******** </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              PasswordRef?.current?.classList.remove("scale-0");
            }}
            className="text-primary flex justify-start items-center gap-2 mb-6 cursor-pointer px-2 py-1 rounded-md w-fit underline underline-offset-2"
          >
            {" "}
            <FaPen /> Change Password
          </button>

          <p className="text-gray-700 text-[1.1rem] font-normal mt-6">
            {" "}
            Joined:{" "}
          </p>
          <p className="text-[1.2rem] font-semibold">
            {" "}
            {new Date(user?.createdAt).toLocaleDateString("en-GB")}
          </p>
        </div>
      </form>
    </div>
  );
}

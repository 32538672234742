import React, { useState, useRef, useEffect } from "react";
import AddPartner from "../components/AddPartner";
import { FaCheck, FaExternalLinkAlt, FaPlus, FaTrash } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import axios from "axios";
import { useMessageData } from "../context/UserContext";

const PartnerManagement = () => {
  const deleteRef = useRef(null);
  const [password, setPassword] = useState("");
  const [id, setId] = useState(null);
  const [partners, setPartners] = useState([]);
  const [isAddPartnerVisible, setIsAddPartnerVisible] = useState(false);
  const { setErr, setMessage, setShow, setIcon } = useMessageData();
  useEffect(() => {
    const GetPartners = async () => {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_API_URL + "partners"
        );
        setPartners(data.partners);
      } catch (error) {
        console.log(error);
      }
    };
    GetPartners();
  }, [isAddPartnerVisible]);

  const addPartnerRef = useRef(null);

  const handleAddPartnerClick = () => {
    setIsAddPartnerVisible(true);
  };

  const handleClickOutside = (event) => {
    if (
      addPartnerRef.current &&
      !addPartnerRef.current.contains(event.target)
    ) {
      setIsAddPartnerVisible(false);
    }
  };

  useEffect(() => {
    if (isAddPartnerVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAddPartnerVisible]);

  const deletePartner = async (e) => {
    e.preventDefault(e);
    try {
      const { data } = await axios.delete(
        process.env.REACT_APP_API_URL + "admin/partner/" + id,
        {
          params: {
            password,
          },
          withCredentials: true,
        }
      );
      const Newpartners = partners.filter((p) => p._id != id);
      setPartners(Newpartners);
      cancelDelete(e);
      setErr(false);
      setMessage("Partner Deleted Successfully");
      setIcon(<FaCheck />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    } catch (error) {
      console.log(error);
      setErr(true);
      setMessage(error?.response?.data?.message || "Fail To Add Partner");
      setIcon(<FaXmark />);
      setShow(true);
      setTimeout(() => setShow(false), 1200);
    }
  };

  const cancelDelete = (e) => {
    e.preventDefault();
    setId(null);
    deleteRef.current.classList.add("scale-0");
  };

  return (
    <div className="h-full w-full bg-gray-50 py-2 px-4 ">
      <div
        ref={deleteRef}
        className="absolute user-card-overlay duration-300 top-0 left-0 w-full h-full flex items-center justify-center bg-black/10 z-[999] scale-0"
      >
        <form className="p-8 bg-white rounded-lg">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter your password"
            className="bg-secondary border-none rounded-md p-2 focus:outline-none mb-6"
            required
          />
          <div className="flex justify-center gap-2 items-center">
            <button
              onClick={deletePartner}
              className="bg-red-500 text-whiteColor px-4 py-2 cursor-pointer font-semibold block rounded-md"
            >
              Delete
            </button>
            <button
              onClick={(e) => cancelDelete(e)}
              className="bg-blue-500 text-whiteColor px-4 py-2 cursor-pointer font-semibold block rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <div className="h-full w-full ">
        <div className="h-[65px] py-2">
          <div className="flex items-center justify-between">
            <h1 className="text-3xl font-bold text-gray-900">Our Partners</h1>
            <button
              onClick={handleAddPartnerClick}
              className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-md hover:bg-primaryHover transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              <FaPlus className="w-5 h-5 mr-2" />
              Add New Partner
            </button>
          </div>
        </div>
        <div className="flex flex-wrap  justify-start items-start gap-6 h-[calc(100%-65px)] overflow-y-auto">
          {partners.map((partner) => (
            <div
              key={partner._id}
              className="group relative bg-white dark:bg-gray-800 w-[300px] rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden border border-gray-200 dark:border-gray-700"
            >
              <button
                onClick={() => {
                  setId(partner?._id);
                  deleteRef.current.classList.remove("scale-0");
                }}
                className="absolute z-50 right-2 top-2 p-2 rounded-full "
              >
                <FaTrash
                  onClick={(e) => {
                    setId(id);
                    deleteRef.current.classList.remove("scale-0");
                  }}
                  className="h-6 w-6 text-red-400 hover:text-primary duration-200 z-50"
                />
              </button>

              <div className="h-48 overflow-hidden p-5">
                <img
                  src={partner?.logo}
                  alt={partner?.name}
                  className="h-full mx-auto"
                />
              </div>

              <div className="p-5">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {partner?.name}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  Partner since{" "}
                  {new Date(partner?.createdAt).toLocaleDateString()}
                </p>

                {partner?.website && (
                  <a
                    href={partner?.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-4 inline-flex w-full items-center justify-center gap-2 rounded-lg border hover:border-primary bg-primaryTra text-primary px-4 py-2 text-sm font-medium transition-colors duration-200"
                  >
                    Visit Website
                    <FaExternalLinkAlt className="h-4 w-4" />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Header Section */}

      {/* Add Partner Modal */}
      {isAddPartnerVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div
            ref={addPartnerRef}
            className="bg-white rounded-lg shadow-xl w-full max-w-[600px]"
          >
            <div className="flex items-center justify-between p-6 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-900">
                Add New Partner
              </h2>
              <button
                onClick={() => setIsAddPartnerVisible(false)}
                className="text-primary hover:text-primaryHover focus:outline-none focus:text-primaryHover"
              >
                <FaXmark className="w-6 h-6" />
              </button>
            </div>
            <div className="p-6">
              <AddPartner />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerManagement;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const Field = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const containerRef = useRef(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_API_URL + "categories"
        );
        setCategories(data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          setTimeout(() => setShowScroll(true), 1000);
        }
      },
      { threshold: 0.7 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full overflow-hidden  h-[50vh] sm:h-[60vh] md:h-[80dvh] relative my-10"
    >
      <img
        className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-full "
        src="/field.jpg"
      />
      <h1 className="text-[1.8rem] md:text-[2.8rem] capitalize text-center relative font-bold text-whiteColor pt-5">
        {" "}
        What we teach
      </h1>
      <div className="w-full h-[calc(100%-65px)]">
        <div className="relative pt-2 pb-2 h-1/2 flex justify-center items-center">
          <div
            className={`flex gap-10 ${
              showScroll ? "animate-[scrollField_20s_linear_infinite]" : ""
            }`}
          >
            {[...categories, ...categories, ...categories].map((cat, index) => (
              <div
                key={cat._id}
                className={`
                flex-shrink-0 w-60 h-52 md:w-72 md:h-64 lg:w-80 lg:h-72 relative
                transition-all duration-500
                ${
                  isVisible
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 -translate-y-full"
                }
              `}
                style={{
                  transitionDelay: `${index * 200}ms`,
                }}
              >
                {/* Post-it note background */}
                <img
                  className="absolute w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  src="/postit.png"
                />
                {/* Category text */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-2xl md:text-3xl font-bold text-gray-800 transform -rotate-2">
                    {cat.name}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="relative pt-2 pb-4 h-1/2 flex justify-center items-center">
          <div
            className={`flex gap-10 ${
              showScroll ? "animate-[scrollReverse_15s_linear_infinite]" : ""
            }`}
          >
            {[...categories, ...categories, ...categories].map((cat, index) => (
              <div
                key={cat._id}
                className={`
                flex-shrink-0 w-60 h-52 md:w-72 md:h-64 lg:w-80 lg:h-72 relative
                transition-all duration-500
                ${
                  isVisible
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-full"
                }
              `}
                style={{
                  transitionDelay: `${index * 200 + 500}ms`,
                }}
              >
                <img
                  className="absolute w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  src="/postit.png"
                />

                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-2xl md:text-3xl font-bold text-gray-800 transform rotate-2">
                    {cat.name}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Field;

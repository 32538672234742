import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    const GetPartners = async () => {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_API_URL + "partners"
        );
        setPartners(data.partners);
      } catch (error) {
        console.log(error);
      }
    };
    GetPartners();
  }, []);
  return (
    <div className="w-full p-8 bg-whiteColor relative">
      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold text-whiteColor mb-4">
          Our Trusted Partners
        </h2>
      </div>

      <div className="w-full md:w-[90%] mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">
        {partners.map((partner, index) => (
          <div
            key={index}
            className="group cursor-pointer group relative bg-black/5 rounded-md overflow-hidden hover:shadow-xl transition-shadow duration-300"
          >
            {/* Logo container */}
            <div className="h-48 flex items-center justify-center relative p-6">
              {" "}
              <img
                src={partner.logo}
                alt={`${partner.name} logo`}
                className=" object-contain rela h-full"
              />
            </div>
            <div className="absolute top-100% group duration-300 group-hover:top-0 left-0 w-full h-full bg-[#00000031] flex justify-center items-center">
              {partner?.website && (
                <a
                  target="_blank"
                  href={partner?.website}
                  className="flex scale-0 group-hover:scale-100 justify-center text-[1.1rem] items-center gap-3 bg-white px-4 py-2 rounded-full hover:bg-primary hover:text-white duration-300 cursor-pointer"
                >
                  {" "}
                  Visit Website <FaExternalLinkAlt />{" "}
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;

